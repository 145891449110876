function like (e) {
  e.preventDefault();
  const button = e.target;
  if (window['current_user'] && current_user.id != undefined){
    if (button.classList.contains('active')){
      button.classList.remove('active');
    } else {
      button.classList.add('active');
    }

    function update_count ( count ){
      let display = document.querySelector('.like_count');
      if(display != undefined){
        let span = display.querySelector('span');
        let num = Number(span.innerHTML);
        span.innerHTML = num + count;
        if(num <= 0){
          display.classList.add('hidden');
        } else {
          display.classList.remove('hidden');
        } 
      }
    }

    let xhr = new XMLHttpRequest();
    xhr.open('POST', '/api/bookmarks/like');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onload = function() {
      if (xhr.status === 201) {
        const data = JSON.parse(xhr.responseText);
        button.classList.add('active');
        button.innerHTML = 'Liked';
        update_count(+1);
        current_user.liked_photos.push(button.dataset['resourceId']);
      } else if (xhr.status === 202){
        const data = JSON.parse(xhr.responseText);
        button.classList.add('remove');
        button.innerHTML = 'Like';
        update_count(-1);      
      }
    };
    xhr.send(JSON.stringify({
      resource_id: button.dataset['resourceId'],
      resource_type: button.dataset['resourceType']
    }));
  }
}
window.like = like;
module.exports = like;

function feature (e) {
  e.preventDefault();
  const button = e.target;
  if (window['current_user'] && current_user.id != undefined){
    let xhr = new XMLHttpRequest();
    xhr.open('POST', `/api/photos/${button.dataset['resourceId']}/feature`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onload = function() {
      if (xhr.status === 201) {
        const data = JSON.parse(xhr.responseText);
        button.classList.add('active');
        button.innerHTML = 'Featured';
      }
    };
    xhr.send(JSON.stringify({
      resource_id: button.dataset['resourceId'],
      resource_type: button.dataset['resourceType']
    }));
  }
}
window.feature = feature;
module.exports = feature;