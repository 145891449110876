function set_ga_user (params) {
  ['dimension11', 'dimension12', 'dimension13', 'dimension14', 'userId', 'user_id'].forEach(e => delete params[e]);
  if (current_user != undefined){
    params['userId'] = current_user['id'];
    params['user_id'] = current_user['id'];
    params['dimension11'] = current_user['invite_token'];
    params['dimension12'] = current_user['state'];
    params['dimension13'] = current_user['user_type'];
    params['dimension14'] = current_user['campaigns'];
  }
  //console.log(['ga_params', params])
  return params
}

function set_utm_params_cookie () {
  var q;
  if (location.search.match(/(utm_medium)/)) {
    q = queryString.parse(location.search);
    q['referrer'] = document.referrer;
    return cookies.set("utm_params", q, false, "/");
  }
};

function track_event (category, action, label, hash) {
  if (action == null) {
    action = null;
  }
  if (label == null) {
    label = null;
  }
  if (hash == null) {
    hash = null;
  }
  if (!!(hash === null && label !== null && action !== null)) {
    hash = {
      content_name: label,
      content_category: category
    };
  }
  if (typeof fbq !== 'undefined') {
    return fbq('track', category, hash);
  }
};

function track_action(event, category, action, label) {
  if (typeof ga !== 'undefined') {
    new Promise(function(resolve, reject) {
      ga('send', 'event', category, action, label);
      return resolve("ga_event");
    }).then(function(value) {
      return window.open(event.target.href, '_blank');
    });
  } else {
    window.open(event.target.href, '_blank');
  }
  return false;
};

window.set_utm_params_cookie = set_utm_params_cookie;
window.set_ga_user =  set_ga_user;
window.track_event =  track_event;
window.track_action =  track_action;

Events.add(document,'userReady', function(e){
  set_utm_params_cookie();
})
if (typeof module !== 'undefined') {
  module.exports = { set_ga_user, set_utm_params_cookie, track_event, track_action  }
}