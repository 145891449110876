App.Filters = (() => {
  class Filters {
    constructor(options){
      this.selector = options.selector;
      this.links = document.querySelectorAll(this.selector);
      this.input = document.querySelector(options.input);
      this.inputs = document.querySelectorAll(this.selector+' input');
      this.setup();
      this.update();
    }

    setup(){
       this.links.forEach((link)=>{
        link.onclick = (e) => {
          e.preventDefault();
          link.querySelector('input').value = '1';
          this.update();
        }
      })
    }

    selected(){
      return Array.from(this.inputs).filter((i)=>{ return i.value === '1' })
    }

    update(){
      this.inputs.forEach((input)=> {
        if(input.value === '1'){
          input.closest(this.selector).classList.add('selected');
        }      
      })
      const ids = this.selected().map((i) => { return i.dataset['resourceId']});
      this.input.value =  ids;
    }
  }
  return Filters
})()