Events.add(document,'document_ready', function(e){
  document.querySelectorAll('.show-more-button').forEach((button) => {
    button.onclick = (e) => {
      const el = e.target.closest('.dynamic-height-container');
      if (el.classList.contains('closed')){
        el.classList.remove('closed');
        e.target.inner_html = 'Read More';
      } else {
        el.classList.add('closed');
        e.target.inner_html = 'Read Less';
      }
    }
  })
})