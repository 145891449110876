const Modal = {

  request (data, callback) {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', data.url);
    xhr.onload = () => {
      if (xhr.status === 200) {
        delete data.url;
        data.body = xhr.responseText;
        callback(data);
      }
    };
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send()
  },

  load (data) {
    if(current_user.id == undefined && data.preview=='false'){
      data.url = '/users/sign_up'
    }
    if (data.body != undefined && data.body.trim() != '') {
      document.querySelector('.modal-content .modal-title').innerHTML = data.title ||'';
      const body = document.querySelector('.modal-content .modal-body');
      body.innerHTML = "";
      setInnerHTML(body, data.body);
      Modal.open(data);
    } else if(data.url != undefined){
      Modal.request(data, Modal.load);
    }
  },

  open(data = {}){
    Modal.modal.className = "modal";
    Modal.wrap.classList.add('pointer-events-none');
    Modal.body.classList.add('modal-active', 'stop-scrolling');
    Modal.modal.classList.add('fixed', 'w-full', 'h-full','top-0','left-0','flex', 'items-center','justify-center','z-50');
    Modal.container.setAttribute('class', 'modal-container w-9/12 w-full max-h-screen mx-auto rounded-xl shadow-lg z-50 overflow-y-auto');
    Modal.container.classList.add(...(data.modalClass ? data.modalClass : 'max-w-lg,mx-auto').split(","))
    Modal.modal.classList.remove('opacity-0','pointer-events-none');
    Modal.load_modals();
    if(typeof data.afterOpen === "function"){
      data.afterOpen()
    }
  },
  close(){
    if (!!(document.location.hash.match(/^(#photo)/))) { location.hash = ''; }
    Modal.wrap.classList.remove('pointer-events-none');
    Modal.body.classList.remove('modal-active', 'stop-scrolling');
    Modal.modal.classList.add('opacity-0','pointer-events-none');
  },

  promoScroll () {
    if (window['current_user'] && current_user.id == undefined && document.body.classList.contains('promotion') && !(/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) && cookies.get("promotion") == null){
      if (document.body.scrollTop > 350 || document.documentElement.scrollTop > 350){
        cookies.set("promotion", true, 60*60*24*7, "/")
        let params = location.search.substring(1) || "utm_medium=web";
        params = JSON.parse('{"' + decodeURI(params).replace(/"/g, '\\"').replace(/&/g, '","').replace(/\=/g,"\":\"") + '"}')
        if (params['utm_medium'] != "email"){
          if(!(document.body.classList.contains('modal-active'))){
            Modal.load({
              title: 'Do you love adventure?',
              body: `<p>We'll send you the best local adventures, new gear, and travel advice, right to your inbox.</p>
              <form class="subscribe" id="subscribe_modal" action="/users/enter" accept-charset="UTF-8" method="post">
              <div class="control-group" style="margin: 0 0 1rem 0;">
                <input required="required" placeholder="Your Email" autocomplete="off" onblur="checkUserEmail(this);" type="text" value="" name="user[email]" id="user_email">
                <div class="email_hint"></div>
              </div>
              <input value="pending" type="hidden" name="user[state]" id="user_state">
              <input value="promotion" type="hidden" name="user[campaign]" id="user_campaign">
              <div class="flex justify-end pt-2">
                <input type="submit" name="commit" value="Subscribe" class="button green px-4 p-3 rounded-lg" data-disable-with="Subscribe">
                <a hgref="#" class="modal-close px-4 p-3 rounded-lg" onclick="Modal.close();">No, Thanks</a>
              </div>
              </form>`
            });
          }
        }
      }
    }
  },

  loadContributeModal () {
    this.load({body: `<div class='py-4'>
    <h3 class="font-bold tracking-tight text-2xl mb-2">Submit a New Adventure</h3>
    <p class="text-gray-600 mb-4">Adventures are  guides about specific hikes, cool photo spots, or other activities. These usually include details like distance, directions, and step-by-step information.<p>
    <a class='px-12 py-4 border border-gray-300 font-bold text-lg w-full inline-block text-center no-underline' href='/adventures/new' rel='noindex nofollow'>Add an Adventure</a>
    <h3 class="font-bold tracking-tight text-2xl mb-2 mt-8">Submit a Story</h3>
    <p class="text-gray-600 mb-4">Stories are trip reports, blogs, news, lists, videos, photo essays, or other thoughtful articles about travel and adventure.<p>
    <a class='px-12 py-4 border border-gray-300 font-bold text-lg w-full inline-block text-center no-underline' href='/stories/new' rel='noindex nofollow'>Add a Story</a>
    </div>`})
  },

  loadShareModal (title='Your story has been published!', description="It is now live on your personal profile. Our editors will let you know if they decide to make it a featured story on the site. Share it far and wide with friends, family, and the Outbound community. Thanks for contributing!", subject="Check out my new story on the Outbound!", url=undefined) {
    atts = "?utm_source=theoutbound.com&utm_medium=email&utm_campaign=sharing"
    if (current_user && current_user.invite_token)
      atts += "&cc=#{current_user.invite_token}"
    html = "<p class='pad1t'>"+description+"</p>"
    if (url != undefined)
      url += encodeURIComponent(atts)
      html += "<div class='modal-footer row row-fluid'>"
      html += "<a href='#' onclick='share.facebook();return false;' class='button fb m1r'>Facebook</a>"
      html += "<a href='#' onclick='share.twitter();return false;' class='button tw m1r'>Twitter</a>"
      html += "<a class='button' href='mailto:?subject="+subject+"&body="+url+"'>Email</a></div>"

    load({ title: title, body: html })
  },

  loadPhoto () {
    if (!!(document.location.hash).match("photo"))
      this.load({url: "/photos/"+document.location.hash.split("-").pop(), modalClass: 'w-wide,photos'});
  },

  load_modals () {
    document.querySelectorAll('a[data-preview=false]').forEach((el,i) =>{
      if(current_user.id === undefined){
        el.dataset['modal'] = true;
      }
    })
    document.querySelectorAll('a[data-modal],a[data-url]').forEach((el,i) =>{
      el.onclick = function (e) {
        e.preventDefault;
        const data = Object.assign({},  Object.assign( e.target.dataset, {url: e.target.dataset.url || el.href }));
        Modal.load(data);
        return false;
      }
    })
  },

  init () {
    this.body = document.querySelector('body');
    this.wrap = document.querySelector('#wrap');
    this.modal = document.querySelector('.modal');
    this.container = document.querySelector('.modal-container');
    this.scripts = document.querySelector('.modal-scripts');
    window.addEventListener('scroll', this.promoScroll);
    this.loadPhoto();

    const overlay = document.querySelector('.modal-overlay')
    if(overlay){
      overlay.addEventListener('click', Modal.close)
    }

    var closemodal = document.querySelectorAll('.modal-close')
    for (var i = 0; i < closemodal.length; i++) {
      closemodal[i].addEventListener('click', Modal.close)
    }

    document.onkeydown = function(evt) {
      evt = evt || window.event
      var isEscape = false
      if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc")
      } else {
       isEscape = (evt.keyCode === 27)
      }
      if (isEscape && document.body.classList.contains('modal-active')) {
        Modal.close()
      }
    };
    this.load_modals();
  }
}

function open_modal (e) {
  e.target.dataset['preview'] = false;
  Modal.load(e.target.dataset);
}
window.open_modal = open_modal;

window.load_modals = Modal.load_modals;

window.loadTermsNotice = () => {
  eu_country = (location) => {
    country_codes = ["AT", "BE", "BG", "CY", "CZ", "DE", "DK", "EE", "ES", "FI", "FR", "GB", "GR", "HR", "IE", "IT", "LT", "LU", "LV", "MT", "NL", "PL", "PT", "RO", "SE", "SI", "SK"]
    country_names = ["AUSTRIA", "BELGIUM", "BULGARIA", "CROATIA", "CYPRUS", "CZECH REPUBLIC", "DENMARK", "ESTONIA", "FINLAND", "FRANCE", "GERMANY", "GREECE", "IRELAND", "ITALY", "LATVIA", "LITHUANIA", "LUXEMBOURG", "MALTA", "NETHERLANDS", "POLAND", "PORTUGAL", "ROMANIA", "SLOVAKIA", "SLOVENIA", "SPAIN", "SWEDEN", "UNITED KINGDOM"]
    return(!!(location.continent_code && location.continent_code.toUpperCase() == 'EU') ||
      !!(location.country_code && country_codes.includes(location.country_code.toUpperCase())) ||
      !!(location.country && country_names.includes(location.country.toUpperCase())))
  }
  //current_user.location = {continent_code: 'EU', country_code: 'FR', country: 'France'}
  if (!(!!(typeof cookies != 'undefined') && cookies.get('updated_terms_5_27_18')) && !!(typeof current_user !== 'undefined' && current_user.location && eu_country(current_user.location)))
    App.Flash.new('notice', `<div class="updated_terms row">
    <div class="columns large-10">We use cookies to understand how you use our site and to improve your experience.  This includes personalization of content and advertising.  By continuing to use our site, you accept our use of cookies, revised <a href="/privacy" style="color:#fff;text-decoration:underline;">Privacy Policy</a> and <a href="/terms" style="color:#fff;text-decoration:underline;">Terms of Use</a>.</div>
    <div class="columns large-2 pad1y"><a onclick="closeTermsNotice(); return false;" style="border: 1px solid #fff; color: #fff; border-radius: 3px; font-size:14px; font-weight:bold; padding: 4px 20px; opacity: 1;cursor: pointer;">I Accept</a></div></div>`, 30, false)
}
function closeTermsNotice (e) {
  cookies.set("updated_terms_5_27_18", Date.now(), false, "/")
  App.Flash.close();
}

window.closeTermsNotice  = closeTermsNotice;
loadTermsNotice();

window.addEventListener('hashchange', (function(e) {
  if (!!(document.location.hash.match(/^(#photo)/))) {
    Modal.loadPhoto();
  }
}), false);

window.App.ready(()=> {
  Modal.init();
})

window.Modal = Modal;
module.exports = Modal;
