async function $XHR(method, url, data={}, success=false, failure=false) {
  let xhr = new XMLHttpRequest();
  xhr.open(method, url);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.onload = function() {
    try{
      let data = JSON.parse(xhr.responseText)
      if (xhr.status === 200) {
        return success ? success(data) : data;
      } else {
        return failure ? failure(data) : data;
      }
    } catch(e){
      return failure ? failure({ message: e }) : { message: e }
    }
  }
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  xhr.send(JSON.stringify(data));
}

async function $Fetch(method, url, data={}, success=false, failure=false) {
  fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    body: JSON.stringify(data)
  })
  .then(data => data.json())
  .then((data) => {
    return success ? success(data) : data
  })
  .catch(e=> {
    return failure ? failure(e) : e
  })
}

window.$Fetch = $Fetch;
window.$XHR =$XHR;

if (typeof module !== 'undefined') {
  module.exports = { $XHR, $Fetch }
}