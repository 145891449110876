const Unveil = function(){
  render = function(){
    let source = this.dataset['src'];
    if (window.devicePixelRatio > 1 && !source.match(/\dpr=2/) && !source.match(/mark64=/)) {
      var dpr = source.match(/\?/)? "&dpr=2" : "?dpr=2"
      source = source+dpr
    }
    if (this.tagName === "IMG") {
      this.setAttribute("src", source);
    } else {
      this.style["backgroundImage"] = "url('" + source + "')";
    }
  } 
  let els = document.querySelectorAll('[data-src]')
  els.forEach(el => { render.bind(el)(); })
}
window.Unveil = Unveil;
App.ready(()=> { Unveil()})