App.Flash = (() => {
  const state = {
    notices: [],
    new: (key, value, expiry, close) => {
      state.notices.push([key,value, expiry, close]);
      state.pop();
    },
    pop: () => {
      const vis = document.querySelector('.flash');
      if(!(vis) && state.notices.length > 0){
        return new Flash(...state.notices.pop());
      }
    },
    close: ()=> {
      document.querySelector('.flash').remove();
    }
  }

  class Flash {
    constructor(key, value, expiry, close){
      const page = document.querySelector('#wrap,.container-fluid');
      if(page){
        const flash = this.render(key,value, expiry, close);
        page.prepend(flash)
        scrollTo(0,0)
      }
    }

    render(key, value, expiry=5000, close=true) {
      let el = document.createElement('DIV');
      el.classList.add('flash', key,  'border', 'px-4', 'py-3', 'relative')
      el.innerHTML = `<span>${value}</span>`;
      if (!!close) { this.closeButton(el); }
      if (!!expiry) { this.expires(el, expiry) }
      return el;
    }

    closeButton (el) {
      el.innerHTML += `<a class='close float-right'>&times;</a>`
      el.querySelector('a').onclick = (el) => { this.close(el.target); }
    }

    close(el) {
      el.closest(".flash").remove();
      state.pop();
    }

    expires(el, ms) {
      setTimeout(() => { this.close(el); }, ms);
    }
  }

  parseFlashCookie = () => {
    if (!!(typeof cookies !== 'undefined' && cookies.get('flash') && typeof cookies.get('flash') === "object")) {
      const flash = cookies.get('flash');
      Object.keys(flash).forEach((key,) => {
        state.new(key, flash[key]);
      })
      cookies.set('flash', null, 60, "/");
    }
  }

  readOnly = () => {
    if (!(document.querySelector('header, nav'))) {
      state.new('warn', 'The Outbound is currently read only. Some content or functionality may be unavailable. Please check back later.', false, false);
    }
  };

  App.ready(()=> {
    parseFlashCookie();
    readOnly();
  })
  return state;
})()