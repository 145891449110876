if (typeof module !== 'undefined') {
  var AutoComplete = require("../imports/autocomplete-js");
  window.AutoComplete = AutoComplete;
}
const Autocomplete = (options={}, selector) => {

  var params = Object.compact({ 
    limit: options.limit || 5,
    type: options.type || 'City,Region,Country',
    no_new_records: options.no_new_records
  })

  document.querySelector(selector).oninput = (e) =>{
    if (e.target.value.length == 0 && options.clear){
      options.clear();
    } else if(typeof options.onchange === 'function'){
      options.onchange(e.target.value);
    }
  }

  AutoComplete({
    QueryArg: 'like',
    Url: '/api/places/autocomplete.json?'+toParam(params),
    MinChars: 3,
    _Render: function (response) {
      try{
        response = JSON.parse(response).map(item => {
          return Object.assign(item, {Value: item.short_name, Label: item.short_name, name: item.short_name })
        })
        var ul = this._RenderResponseItems(response);
        if (this.DOMResults.hasChildNodes()) {
            this.DOMResults.removeChild(this.DOMResults.childNodes[0]);
        }
        this.DOMResults.appendChild(ul);
      } catch(e) {
        //console.log(e, ressponse)
      }
    },

    _RenderResponseItems: function (response) {
      var ul = document.createElement("ul"), li = document.createElement("li"), limit = this._Limit();
      if (limit < 0) {
          response = response.reverse();
      }
      else if (limit === 0) {
          limit = response.length;
      }
      for (var item = 0; item < Math.min(Math.abs(limit), response.length); item++) {
          li.innerHTML = response[item].Label;
          li.setAttribute("data-autocomplete-value", response[item].Value);
          Object.keys(response[item]).forEach(k => {
            li.setAttribute("data-"+k, response[item][k]);
          })
          ul.appendChild(li);
          li = document.createElement("li");
      }
      return ul;
    },

    _Select: function(item) {
      this.Input.value = item.innerHTML;
      if(typeof options.success === 'function'){
        options.success(item.dataset);
      }
    }
  }, selector);
}

window.Autocomplete = Autocomplete;
if (typeof module !== 'undefined') {
  module.exports = Autocomplete;
}