window.Events = {};

Events.dispatch = function(el, eventName) {
  var event;
  event = void 0;
  if (document.createEvent) {
    event = document.createEvent("HTMLEvents");
    event.initEvent(eventName, true, true);
  } else if (document.createEventObject) {
    event = document.createEventObject();
    event.eventType = eventName;
  }
  event.eventName = eventName;
  if (el.dispatchEvent) {
    el.dispatchEvent(event);
  } else if (el.fireEvent && Events.htmlEvents["on" + eventName]) {
    el.fireEvent("on" + event.eventType, event);
  } else if (el[eventName]) {
    el[eventName]();
  } else {
    if (el["on" + eventName]) {
      el["on" + eventName]();
    }
  }
};

Events.add = function(el, type, handler) {
  if (el.addEventListener) {
    el.addEventListener(type, handler, false);
  } else if (el.attachEvent && Events.htmlEvents["on" + type]) {
    el.attachEvent("on" + type, handler);
  } else {
    el["on" + type] = handler;
  }
};

Events.remove = function(el, type, handler) {
  if (el.removeventListener) {
    el.removeEventListener(type, handler, false);
  } else if (el.detachEvent && Events.htmlEvents["on" + type]) {
    el.detachEvent("on" + type, handler);
  } else {
    el["on" + type] = null;
  }
};

Events.htmlEvents = {
  onload: 1,
  onunload: 1,
  onblur: 1,
  onchange: 1,
  onfocus: 1,
  onreset: 1,
  onselect: 1,
  onsubmit: 1,
  onabort: 1,
  onkeydown: 1,
  onkeypress: 1,
  onkeyup: 1,
  onclick: 1,
  ondblclick: 1,
  onmousedown: 1,
  onmousemove: 1,
  onmouseout: 1,
  onmouseover: 1,
  onmouseup: 1
};
