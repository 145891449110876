//cookies.js :: javascript cookie utility

window.cookies = {}

cookies.get = function(sKey) {
  var data = decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  if (data !== null && data.match(/\{.*\}/)) {
    return JSON.parse(data.replace(/\+/g, ' '));
  } else {
    return data;
  }
};

cookies.set = function(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
  var sValue = JSON.stringify(sValue);
  if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
    return false;
  }
  sExpires = "";
  if (vEnd) {
    switch (vEnd.constructor) {
      case Number:
        sExpires = (vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd);
        break;
      case String:
        sExpires = "; expires=" + vEnd;
        break;
      case Date:
        sExpires = "; expires=" + vEnd.toUTCString();
    }
  }
  document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
  return true;
};

cookies.delete = function(sKey, sPath, sDomain) {
  if (!sKey || !this.hasItem(sKey)) {
    return false;
  }
  document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
  return true;
};

cookies.hasItem = function(sKey) {
  return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
};

cookies.keys = function() {
  var aKeys, nIdx;
  aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
  nIdx = 0;
  while (nIdx < aKeys.length) {
    aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
    nIdx++;
  }
  return aKeys;
};

cookies.clear = function() {
  var i, key, len, ref, results;
  ref = Object.keys(cookies.keys);
  results = [];
  for (i = 0, len = ref.length; i < len; i++) {
    key = ref[i];
    results.push(cookies["delete"](key));
  }
  return results;
};
