// App Namespace

const App = (() => {
  const App = {
    ready: (callbackFunction, event) => {
      if(document.readyState != 'loading'){
        callbackFunction(event)
      } else {
        document.addEventListener("DOMContentLoaded", callbackFunction)
      }
    }
  }
  App.ready(()=>{
    if(typeof current_user !== 'undefined'){
      window.current_user = new App.CurrentUser(current_user||{});
    }
    Events.dispatch(document, 'document_ready');
  })
  return App;
})()
window.App = App;
if (typeof module !== 'undefined') {
  module.exports = App;
}
