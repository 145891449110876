class Kit {
  constructor(options) {
    this.options = options;
    this.url = "/api/kits";
    if(options.kit_id){
      this.url += "/"+options.kit_id
    }
    this.init()
  }

  init(){
    const response = this;
    const xhr = new XMLHttpRequest();
    const params = {
      tags: this.options["tags"]||[],
      limit: this.options["limit"]||2,
      api_key: this.options["api_key"]
    }
    xhr.open('GET',this.url+'.json?' + toParam(params) );
    xhr.onload = function() {
      if (xhr.status === 200) {
        response.render(JSON.parse(xhr.responseText));
      } else {
        console.error('Request failed.  Returned status of ' + xhr.status);
      }
    };
    xhr.send();
  }

  render(data){
    const kit = (Array.isArray(data) ? data[0] : data);
    if (kit.id){
      let pixels = ""
      let html = "<div class='container mx-auto '><div class='pad2b'>"
      html += "<div class='flex flex-wrap my-6 items-center'>"
      html += "<div class='flex-1 items-center' style='min-width:320px;'>"
      html += "<h3 class='text-3xl font-medium compress'>"+kit.name+"</h3>"
      html += "<a class='py-2 text-lg font-medium compress'>By: <img class='my-2' src='https://images.theoutbound.com/assets/partners/Moosejaw.png' style='height:30px; display:inline;' href='http://www.moosejaw.com/'/></a>"
      html += "</div>"
      html += "<a class='py-2 items-center' href='https://www.moosejaw.com/content/gear-wizard' style='min-width:320px;'>Have a question about the gear you need? <img class='py-2' src='https://images.theoutbound.com/assets/partners/MoosejawWizard.png' style='max-width:320px;display:inline;'/></a>"
      html += "</div><div class='grid grid-cols-2 md:grid-cols-6 gap-4 md:gap-8'>"
      kit.gear.forEach((gear, index) => {
        if (index < 8){
          html+= "<div class='col-span-1'><div><a href='"+gear.url+"' target='_blank'><div class='adventure-kit--image'><img loading='lazy' src='"+gear.content_url+"?&fit=crop&w=190&q=70' title='"+gear.name+"'/></div><div class='font14 pad1t adventure-kit--title color-dark text-center bold'>"+gear.name+"</div></a></div></div>"
          if(gear['tracking_pixel'] != undefined && gear['tracking_pixel'] != ''){
            pixels += "<img src='"+gear['tracking_pixel']+"' width='1' height='1' style='visibility: hidden;'/>"
          }
        }
      })
      html += "</div>"
      html += pixels
      html += "</div>"
      document.querySelector(this.options.target).innerHTML = html;
    }
  }
}

window.Kit = Kit;
module.exports = Kit;